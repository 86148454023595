import React from 'react';

import relablogo from './relablogo.svg';

import Box from '../components/Box';
import Flex from '../components/Flex';
import Image from '../components/Image';
import Link from '../components/Link';
import { mobileOrDesktop } from '../components/ThemeProvider/theme'

const y = new Date().getFullYear()
const Footer = (props) => {
  return (
    <Flex fontSize="0.8em" alignItems="flex-end" justifyContent={mobileOrDesktop('center', 'flex-end')} py="1em" {...props}>
      <Box px={mobileOrDesktop('1em', '3em')} borderRight="1.5px solid black">Copyright © {y}. All rights reserved.</Box>
      <Box px={mobileOrDesktop('1em', '2em')} width={mobileOrDesktop('10em', '12em')}>
        <Link href="https://relab.cc/">
          <Image src={relablogo} />
        </Link>
      </Box>
    </Flex>
  );
};

export default Footer;
