import React, { PureComponent } from 'react'
import { Location } from '@reach/router'
import throttle from 'lodash/throttle'

import headerIcon from './headerIcon.svg'
import Box from '../components/Box';
import Flex from '../components/Flex';
import Link from '../components/Link';
import Image from '../components/Image';
import CircleButton from '../components/CircleButton';
import { mobileOrDesktop } from '../components/ThemeProvider/theme'

const pages = [
  {
    name: '投票\n戀愛月',
    to: '/'
  },
  {
    name: '今年\n遇到你',
    to: '/predict'
  },
  {
    name: '情話\n產生器',
    to: '/words'
  },
  // {
  //   name: '愛情\n選物店',
  //   to: '/shop'
  // },
]

class Header extends PureComponent {
  constructor(props) {
    super(props)
    this.handleScroll = throttle(this.handleScroll, 300)
  }

  state = {}

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getScrollY = () => {
    const supportPageOffset = window.pageXOffset !== undefined;
    const isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

    return supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
  }

  handleScroll = () => this.setState({ shadow: this.getScrollY() > 0 })

  render() {
    const { shadow } = this.state
    return (
      <Flex
        position="fixed"
        top={0}
        left={0}
        px={mobileOrDesktop('0.6em', '2.75em')}
        alignItems="center"
        zOrder={2}
        bg="white"
        boxShadow={`0px 2px 4px rgba(0,0,0,${shadow ? 0.05 : 0})`}
        width="100%"
        justifyContent="space-between"
        transition="box-shadow 0.5s"
        {...this.props}
      >
        <Box px="1em" minWidth="6em" width={mobileOrDesktop('40%', '15%')}>
          <Link to="/" color="white">
            <Image src={headerIcon} />
          </Link>
        </Box>
        <Location>
          {({ location }) => (
            <Flex fontSize={mobileOrDesktop('0.8em', '1em')}>
              {pages.map((page, i) => (
                <Box mx="0.5em" width="4.5em" key={i}>
                  <CircleButton
                    borderWidth="1.5px"
                    lineHeight="1.25"
                    is={(p) => <Link to={page.to} {...p} />}
                    fontSize="0.75em"
                    whiteSpace="pre"
                    textAlign="center"
                    fontFamily="Noto Serif TC"
                    active={page.to === location.pathname}
                  >
                    {page.name}
                  </CircleButton>
                </Box>
              ))}
            </Flex>
          )}
        </Location>
      </Flex>
    )
  }
}

export default Header
