import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { withPrefix } from 'gatsby-link';
import isFunction from 'lodash/isFunction'

import Header from './Header'
import Footer from './Footer'
import Box from '../components/Box';
import theme from '../components/ThemeProvider/theme';
import VerticalCenter from '../components/VerticalCenter'
import withResponisve from '../hoc/withResponsive'

class Layout extends PureComponent {
  state = {
    isCenter: true
  }

  handleCenterState = (isCenter) => this.setState({ isCenter })

  render() {
    const { children, isMobile } = this.props;
    const { isCenter } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title,
                description,
                url
              }
            }
          }
        `}
        render={({ site: { siteMetadata: { title, description, url } } }) => (
          <div>
            <Helmet>
              <html lang="zh-Hant" />
              <title>{title}</title>
              <meta name="description" content={description} />
              <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('/apple-touch-icon.png')} />
              <link rel="icon" type="image/png" sizes="32x32" href={withPrefix('/favicon-32x32.png')} />
              <link rel="icon" type="image/png" sizes="16x16" href={withPrefix('/favicon-16x16.png')} />
              <link rel="mask-icon" color="#e81e5c" href={withPrefix('/safari-pinned-tab.svg')} />
              <meta name="msapplication-TileColor" content="#da532c" />
              <meta name="theme-color" content="#ffffff" />
              <meta property="og:url" content={url} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content={`${url}/fb.png`} />
            </Helmet>
            <Header height={theme.headerHeight} />
            <Box height={isCenter && !isMobile ? '100vh' : 'auto'} minHeight="100vh" pt={theme.headerHeight} mb={isCenter && !isMobile && `-${theme.footerHeight}`}>
              {isMobile ? isFunction(children) ? children() : children : (
                <VerticalCenter onChange={this.handleCenterState}>
                  {isFunction(children) ? children(isCenter) : children}
                </VerticalCenter>
              )}
            </Box>
            <Footer />
          </div>
        )}
      />
    );
  }
}

export default withResponisve(Layout)
