import React from 'react';
import Circle from './Circle';
import Button from './Button';

const CircleButton = ({ disabled, href, is, color, active, ...props }) => (
  <Circle
    is={(p) => (
      <Button
        px="0"
        py="0"
        color={color}
        borderRadius="50%"
        disabled={disabled}
        href={href}
        is={is}
        active={active}
        {...p}
      />
    )}
    {...props}
  />
)

export default CircleButton;
